<template>
  <Dialog v-model:visible="visible" :style="{width: '450px'}" header="Submission details" :modal="true" class="grid p-fluid">
    <div class="col-12 md:col-12">

      <div class="field">
        <label for="wizard" class="mb-3">Wizard</label>
        <Dropdown id="wizard" v-model="entityData.wizard_id" :options="wizards" option-label="name" placeholder="Select a Wizard" option-value="id">
        </Dropdown>
      </div>

      <div class="field">
        <label for="customer" class="mb-3">Customer</label>
        <Dropdown id="customer" v-model="entityData.customer_id" :options="customers" option-label="name" placeholder="Select a Customer" option-value="id">
        </Dropdown>
      </div>

      <div class="field">
        <label for="country" class="mb-3">Country</label>
        <Dropdown id="country" v-model="entityData.country_id" :options="countries" option-label="name" placeholder="Select a Country" option-value="id">
        </Dropdown>
      </div>

      <div class="grid">
        <div class="col-12 mb-2 lg:col-12 lg:mb-0">
          <label for="reg-number">Reg Number</label>
          <InputText id="reg-number" v-model.trim="entityData.reg_number" />
        </div>
      </div>

      <div class="grid">
        <div class="col-12 mb-2 lg:col-12 lg:mb-0">
          <label for="current-step">Current Step</label>
          <InputText id="current-step" v-model.trim="entityData.current_stepr" />
        </div>
      </div>

      <div class="field">
        <label for="data">Data</label>
        <Textarea id="data" v-model="entityData.data" required="true" rows="3" cols="20" />
      </div>

      <div class="grid">
        <div class="col-12 mb-2 lg:col-12 lg:mb-0">
          <label for="payment-status">Payment Status</label>
          <InputText id="payment-status" v-model.trim="entityData.payment_status" />
        </div>
      </div>

      <div class="grid">
        <div class="col-12 mb-2 lg:col-12 lg:mb-0">
          <label for="payment-type">Payment Type</label>
          <InputText id="payment-type" v-model.trim="entityData.payment_type" />
        </div>
      </div>

    </div>

    <template #footer>
      <Button label="Cancel" icon="pi pi-times" class="p-button-text" @click="$emit('hideDialog')"/>
      <Button label="Save" icon="pi pi-check" class="p-button-text" @click="save" />
    </template>
  </Dialog>
</template>

<script>

import SubmissionService from "@/service/SubmissionService";
import CountryService from "@/service/CountryService";
import WizardService from "@/service/WizardService";
import CustomerService from "@/service/CustomerService";

export default {
  name: "SubmissionForm",
  emits: ['hideDialog','saved'],
  props: {
    entity: Object,
    dialog: Boolean
  },
  countryService: null,
  wizardService: null,
  customerService: null,
  entityService: null,
  mounted() {
    this.countryService.fetchAll().then(data => this.countries = data.data);
    this.wizardService.fetchAll().then(data => this.wizards = data.data);
    this.customerService.fetchAll().then(data => this.customers = data.data);
  },

  computed: {
    visible: {
      get() { return this.dialog },
      set(visible) {this.$emit('hideDialog', visible)}
    },
    entityData: {
      get() { return this.entity },
    }
  },

  created() {
    this.countryService = new CountryService();
    this.wizardService = new WizardService();
    this.customerService = new CustomerService();
    this.entityService = new SubmissionService();
  },

  data() {
    return {
      submitted: false,
      countries: [],
      wizards: [],
      customers: [],
      inputTypes: [],
    }
  },

  methods: {
    save() {
      this.submitted = true;

      if (this.entity.id) {
        this.entityService.update(this.entityData).then(response => {
          this.$toast.add({severity:'success', summary: 'Success', detail: 'Submission updated', life: 3000});
          this.$emit('saved', response);
        });
      } else {
        this.entityService.create(this.entityData).then(response => {
          this.$toast.add({severity:'success', summary: 'Success', detail: 'Submission created', life: 3000});
          this.$emit('saved', response);
        });
      }
    },
  }
}
</script>

<style scoped>

</style>
