import http from './HttpService';
import axios from "axios";
export default class WizardService {
    fetchAll() {
        return http.get(`wizard?per_page=300`).then(d => d.data);
    }

    getWizards() {
        return http.get(`wizard?include=country&per_page=100`).then(d => d.data);
    }

    createWizard(wizard) {
        return http.post(`wizard`, wizard).then(response => response.data);
    }

    updateWizard(wizard) {
        return http.put(`wizard/${wizard.id}`, wizard).then(response => response.data);
    }

    deleteWizard(wizard) {
        return http.delete(`wizard/${wizard.id}`).then(response => response.data);
    }

    bulkDeleteWizard(wizards) {
        const requests = [];
        for(const wizard of wizards) {
            requests.push(http.delete(`wizard/${wizard.id}`));
        }

        return axios.all(requests).then()
    }
}
