import http from "@/service/HttpService";
import axios from "axios";

export default class SubmissionService {

    fetchAll() {
        return http.get(`submission?per_page=100`).then(d => d.data);
    }

    create(entity) {
        return http.post(`submission`, entity).then(response => response.data);
    }

    update(entity) {
        return http.put(`submission/${entity.id}`, entity).then(response => response.data);
    }

    delete(entity) {
        return http.delete(`submission/${entity.id}`).then(response => response.data);
    }

    bulkDelete(entities) {
        const requests = [];
        for(const entity of entities) {
            requests.push(http.delete(`submission/${entity.id}`));
        }

        return axios.all(requests).then()
    }
}
